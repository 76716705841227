@import "var";

@font-face {
    font-family: "Segoe";
    src: url("../assets/Segoe_UI.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
  
  @font-face {
    font-family: "Segoe";
    src: url("../assets/Segoe_UI_Bold.woff") format("woff");
    font-weight: italic;
    font-style: normal;
}
  
  @font-face {
    font-family: "Segoe";
    src: url("../assets/Segoe_UI_Bold_Italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}
  
@font-face {
    font-family: "Segoe";
    src: url("../assets/Segoe_UI_Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

body {
    font-family:"Segoe UI", "Segoe", sans-serif;
    font-size: 16px;
    color: #000000; 
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:clamp(20px, 4vw, 40px);
}

h2 {
	font-size:clamp(18px, 4vw, 30px);
    font-weight: 700;
    margin-bottom: 1rem;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: clamp(16px, 3vw, 1rem);
    font-weight: normal;
    padding: 10px 20px;
    max-width: 100%;
    color: #221F20;
    background-color: $yellow;
    border: 1px solid $yellow;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: $yellow;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.yellow{
    color:$yellow;
}
.grey{
    color:$grey;
}
.content-heading {
    font-size:clamp(18px, 4vw, 30px);
    font-weight: 700;
}
.light{
	font-size: clamp(16px, 3.5vw, 1.5rem);
    font-weight: 300;
}
.medium{
    font-weight: 500;
}
.display-default{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:1rem;
    @media screen and (max-width:992px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:576px) {
        grid-template-columns:  1fr;
    }
}